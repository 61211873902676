import React from 'react';
import clsx from 'clsx';
import { Breadcrumbs as MuiBreadcrumbs, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

type ItemType = {
  href: string;
  text: string;
};

type P = {
  items: Array<ItemType>;
  onClick: (arg: ItemType) => void;
};

const useStyles = makeStyles((theme: any) => ({
  item: {
    fontSize: theme.typography.pxToRem(13),
  },
  separator: {
    color: theme.palette.secondary.dark,
    marginLeft: 1,
  },
  gray: {
    color: theme.palette.secondary.dark,
  },
  primary: {
    color: theme.palette.primary.main,
  },
}));

const Breadcrumbs: React.FC<P> = ({ items, onClick }) => {
  const classes = useStyles();

  return (
    <MuiBreadcrumbs
      classes={{
        separator: classes.separator,
      }}
      separator=">"
      aria-label="breadcrumb"
    >
      {items.map((el, i, arr) => (
        <Link key={el.text} href={el.href} onClick={() => onClick(el)}>
          <h6
            className={clsx(
              classes.item,
              i + 1 === arr.length ? classes.primary : classes.gray,
            )}
          >
            {el.text}
          </h6>
        </Link>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
