import clsx from 'clsx';

import AuthHooks from '@hooks/api/auth';

import { Button } from '@components/Common';

import { Link } from '@material-ui/core';
import { Check, ExitToApp, Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import Logo from '@components/Auth/Logo';
import Cookies from 'js-cookie';
import RouteMap from '@routes/map';

const Policies = () => {
  const { data: userPolicies } = AuthHooks.useUserPolicies();
  const history = useHistory();

  const acceptPoliciesMutation = AuthHooks.useAcceptUserPoliciesMutation();

  return (
    <div className="min-h-screen flex flex-col items-center justify-between bg-white-600">
      <Logo className="mt-24" />

      <Button
        className=" px-4 absolute top-10 right-10"
        onClick={e => {
          Cookies.remove('public_token');
          history.push(RouteMap.auth.login);
        }}
      >
        <ExitToApp className="text-white text-lg" />

        <span className="ml-4 text-sm font-roboto text-white">Log out</span>
      </Button>
      <div className="divide-y flex flex-col justify-center items-center px-8 py-6 w-full">
        <div className="flex flex-col w-full">
          <div className="bg-white-400 w-full px-4 py-2 rounded">
            <span className="flex justify-between w-full">
              <h3 className="font-roboto text-base w-full">Policies</h3>
              <h3 className="font-roboto text-base w-120">Date Uploaded</h3>
              <h3 className="font-roboto text-base w-10">File</h3>
            </span>
          </div>

          <div className="grid grid-cols-1 py-2">
            {(userPolicies?.data || []).map((policy, i) => (
              <a
                className={clsx(
                  'px-4 h-10 items-center flex flex-row justify-between font-roboto',
                  i % 2 === 0 ? 'bg-white-600' : 'bg-white',
                )}
                download
                target="_blank"
                rel="noreferrer"
                href={policy.file}
              >
                <span className="flex justify-between w-full">
                  <span className="w-full">{policy.title}</span>
                  <span className="w-120">{policy.date_uploaded}</span>
                  <span className="w-10">
                    <Visibility />
                  </span>
                </span>

                {policy.sign ? <Check className="text-primary" /> : null}
              </a>
            ))}
          </div>
        </div>

        <Button
          className="bg-green mt-5 mb-4 ml-auto w-22"
          text="Accept"
          onClick={() => acceptPoliciesMutation.mutate()}
        />
      </div>

      <div className="w-full flex flex-row items-center justify-start p-8">
        <Button
          variant="outlined"
          className="font-roboto text-sm text-gray"
          text="Need Help?"
          href="https://support.specready.co.uk"
        />
      </div>
    </div>
  );
};

export default Policies;
