import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import Config from '@constants/config';

/**
 * Axios defaults
 */
axios.defaults.baseURL = Config.apiBaseUrl;
// axios.defaults.withCredentials = true;

// Headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

/**
 * Request Interceptor
 */
axios.interceptors.request.use(
  async inputConfig => {
    const config = inputConfig;

    try {
      const token = Cookies.get('token');
      const publicToken = Cookies.get('public_token');

      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
      if (publicToken) {
        config.headers.common['public-token'] = publicToken;
      }
    } catch (error) {
      /* Nothing */
    }

    return config;
  },
  error => {
    throw error;
  },
);

/**
 * Response Interceptor
 */
axios.interceptors.response.use(
  res => {
    if (res.data && typeof res.data === 'object') {
      res.data.timeStamp = Date.now();
    }

    // Status code isn't a success code - throw error
    if (!`${res.status}`.startsWith('2')) {
      throw res.data;
    }

    // Otherwise just return the data
    return res;
  },
  (error: AxiosError) => {
    const xError = error;

    if (xError?.response?.data) {
      xError.response.data.timeStamp = Date.now();
    }

    throw xError;
  },
);

export default axios;
