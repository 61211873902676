import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import authHooks from '@hooks/api/auth';
import { ForgotPasswordParams } from '@hooks/api/auth/types';

import { TextField, Button } from '@components/Common';

import Logo from '@components/Auth/Logo';

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Email is not valid!'),
});

const ForgotPassword = () => {
  const forgotPasswordMutation = authHooks.useForgotPasswordMutation();

  const initialValues: ForgotPasswordParams = {
    email: '',
  };

  const form = useForm<ForgotPasswordParams>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<ForgotPasswordParams> = data => {
    forgotPasswordMutation.mutate(data);
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="min-h-screen flex flex-col items-center justify-between bg-white-600"
    >
      <Logo className="mt-24" />

      <h3 className="font-roboto mt-16 text-2xl text-center">
        Welcome to SpecReady
      </h3>

      <div className="flex flex-col justify-center items-center px-8 py-6 w-1/3">
        <Controller
          name="email"
          control={form.control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <TextField
              className="w-full h-10 border my-5"
              placeholder="Email"
              variant="outlined"
              label="Email"
              id="email"
              innerRef={ref}
              error={!!fieldState?.error?.message}
              helperText={fieldState?.error?.message || ''}
              {...rest}
            />
          )}
        />

        <Button
          type="submit"
          className="bg-green mt-5 mb-4 w-60"
          text="Submit"
        />
      </div>

      <div className="w-full flex flex-row items-center justify-start p-8">
        <Button
          variant="outlined"
          className="font-roboto text-sm text-gray"
          text="Need Help?"
          href="https://support.specready.co.uk"
        />
      </div>
    </form>
  );
};

export default ForgotPassword;
