import React from 'react';
import { useHistory } from 'react-router-dom';

function stringify(search: string, obj: Record<string, unknown>) {
  const urlParams = new URLSearchParams(search);

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      urlParams.set(key, String(value));
    } else {
      urlParams.delete(key);
    }
  });

  return urlParams.toString();
}

function useUrlParams<T>() {
  const history = useHistory();

  const [urlParams, setUrlParams] = React.useState(
    new URLSearchParams(history.location.search),
  );

  React.useEffect(() => {
    setUrlParams(new URLSearchParams(history.location.search));
  }, [history.location.search]);

  const paramsObject: { [key: string]: unknown } = {};

  urlParams.forEach((value, key) => {
    if (value === 'true') {
      paramsObject[key] = true;
      return;
    }

    if (value === 'false') {
      paramsObject[key] = false;
      return;
    }

    paramsObject[key] = value;
  });

  const setNewUrlParams = (newValues: Record<string, unknown>) => {
    // Object.entries(newValues).forEach(([key, value]) => {
    //   if (value !== null && value !== undefined) {
    //     urlParams.set(key, String(value));
    //   } else {
    //     urlParams.delete(key);
    //   }
    // });

    const stringifiedParams = stringify(history.location.search, newValues);

    history.push(`${history.location.pathname}?${stringifiedParams}`);
    // history.push(`${history.location.pathname}?${urlParams.toString()}`);
  };

  return [
    paramsObject as unknown as T, // TODO
    urlParams.toString(),
    setNewUrlParams,
  ] as const;
}

export { useUrlParams, stringify };
