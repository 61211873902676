import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import RouteMap from '@routes/map';

import authHooks from '@hooks/api/auth';
import { LoginParams } from '@hooks/api/auth/types';

import { TextField, Button } from '@components/Common';

import Logo from '@components/Auth/Logo';
import { fetchToken } from '../../firebase.js';

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Email is not valid!'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
});

const Login = () => {
  const history = useHistory();
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);

  const initialValues: LoginParams = {
    email: '',
    password: '',
  };

  const form = useForm<LoginParams>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const logniMutation = authHooks.useLoginMutation();

  const onSubmit: SubmitHandler<LoginParams> = data => {
    const newData = {
      ...data,
      device_token: isTokenFound || null,
    };
    logniMutation.mutate(newData);
  };

  return (
    <form
      className="min-h-screen flex flex-col items-center justify-between bg-white-600"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Logo className="mt-24" />

      <h3 className="font-roboto mt-16 text-2xl text-center">
        Welcome to SpecReady
      </h3>

      <div className="px-8 py-6 w-1/3">
        <div className="flex flex-col justify-center items-center">
          <Controller
            name="email"
            control={form.control}
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                className="w-full h-10 border my-5"
                placeholder="Email"
                variant="outlined"
                label="Email"
                id="email"
                innerRef={ref}
                error={!!fieldState?.error?.message}
                helperText={fieldState?.error?.message || ''}
                {...rest}
              />
            )}
          />

          <Controller
            name="password"
            control={form.control}
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                type="password"
                className="w-full h-10 border my-5"
                placeholder="Password"
                variant="outlined"
                label="Password"
                id="password"
                innerRef={ref}
                error={!!fieldState?.error?.message}
                helperText={fieldState?.error?.message || ''}
                {...rest}
              />
            )}
          />

          <Button
            type="submit"
            className="bg-green mt-5 mb-4 w-60"
            text="Login"
          />
          <Button
            variant="text"
            className="p-0 font-roboto text-xs text-gray-800 font-normal"
            text="Forgot My Password"
            onClick={() => history.push(RouteMap.auth.forgotPassword)}
          />
        </div>
      </div>

      <div className="w-full flex flex-row items-center justify-start p-8">
        <Button
          variant="outlined"
          className="font-roboto text-sm text-gray"
          text="Need Help?"
          href="https://support.specready.co.uk"
        />
      </div>
    </form>
  );
};

export default Login;
