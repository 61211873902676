import clsx from 'clsx';
import config from '@constants/config';

import { Logo as LogoIcon } from '@components/Common';

const Logo = ({ className }: { className: string }) => {
  return (
    <div
      className={clsx('flex flex-col items-center justify-center', className)}
    >
      <div className="flex flex-row items-center justify-center">
        <LogoIcon className="w-96 h-32 mr-2" />
      </div>

      <p className="font-roboto text-gray-300 text-sm mt-2">
        Easy and Reliable Food Spec Managament
      </p>
    </div>
  );
};

export default Logo;
